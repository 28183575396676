#spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  background-color: white;
}
/* 
.App {
  text-align: center;
} */

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bare {
  background-color: #000;
}

#postTitle {
  font-size: 48px;
  font-weight: bold;
}

#postSubTitle {
  font-size: 24px;
  font-weight: bold;
}
/* 
#content-container {
  width: 100%;
  /* background-color: #ededed; */
/* height: "100%"; */

/*
@media only screen and (max-width: 600px) {
  #content {
    background-color: lightblue;
  }
} */

/* .b {
  font-weight: bold;
}

.i {
  font-style: italic;
} */

.bg-col-light {
  background: #627f86;
}

.bg-col-mid {
  background: #2c3654;
}

.bg-col-dark {
  background: #172243;
}

.fg-col-light {
  color: #e5e4df;
}

.fg-col-mid {
  color: #352619;
}

.fg-col-dark {
  color: #39151b;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.top {
  display: flex;
  align-items: flex-start;
}

.middle {
  display: flex;
  align-items: center;
}

.bottom {
  display: flex;
  align-items: flex-end;
}
.font-xs {
  font-size: 12px;
}

.font-s {
  font-size: 14px;
}

.font-m {
  font-size: 16px;
}

.font-l {
  font-size: 20px;
}

.font-xl {
  font-size: 30px;
}

.font-serif {
  font-family: 'Merriweather';
}

.font-sans {
  font-family: 'Roboto';
}

.first-letter {
  padding: 0px;
  font-size: 72px;
  float: left;
  line-height: 1;
  display: inline-block;
}

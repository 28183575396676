body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 55px;
  padding-bottom: 12px;
  /* background: #ededed; */
  font-family: Roboto;
  /*
  HeptaSlab
  Merriweather
  Oswald
  Poppins
  Roboto
  */
}

code {
  color: green;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "HeptaSlab";
  src: local("HeptaSlab"), url(./fonts/HeptaSlab.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url(./fonts/Poppins.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url(./fonts/Roboto.ttf) format("truetype");
}

@font-face {
  font-family: "Oswald";
  src: local("Oswald"), url(./fonts/Oswald.ttf) format("truetype");
}

@font-face {
  font-family: "Merriweather";
  src: local("Merriweather"), url(./fonts/Merriweather.ttf) format("truetype");
}

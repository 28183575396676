.postlist-container {
  /* padding: 12px; */
}

.postlist-row {
  /* margin: 0px; */
  padding: 0px;
  background: #ffa;
}

.postlist-title {
  font-weight: bold;
  background: #afa;
}

.postlist-subtitle {
  font-weight: bold;
  background: #aaf;
}

.browser-card-container {
  cursor: pointer;
  margin: 0px;
  padding: 8px;
}

.mobile-card-container {
  cursor: pointer;
  margin: 0px;
}

.browser-card {
  padding: 0px;
  margin: 0px;
}

.mobile-card {
  padding: 0px;
}

/*
HeptaSlab
Merriweather
Oswald
Poppins
Roboto
*/

.browser-card-title {
  font-family: "Merriweather";
  padding-bottom: 8px;
  margin: 0px;
  font-size: 18px;
  font-weight: bold;
}

.mobile-card-subtitle {
  /* padding: 4px; */
}

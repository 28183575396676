.aligned {
  height: 100%;
  opacity: 5%;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  display: flex;
  justify-content: center;
  align-items: center;
}

#overlay {
  height: 100%;
  opacity: 5%;
  /* filter: blur(8px);
  -webkit-filter: blur(8px); */
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #fff;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  color: #fff;
  z-index: 100;
  opacity: 0.5; */
}
